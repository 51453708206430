.react-pdf__Page {
  margin: 0 !important;
  padding: 0 !important;
  min-height: fit-content !important;
  height: fit-content !important;
  
  canvas {
    border-radius: 8px;
    margin: 0;
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

.react-pdf__message {
  width: 100%;
  height: 100%;
}
