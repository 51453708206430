/* see https://github.com/themesberg/flowbite-admin-dashboard/blob/main/src/css/input.css */

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #3363e9;
  --primary-highlight: #2b55d3;

  --nav-background: #ffffff;
  --nav-text: #4d5562;
  --nav-text-highlight: #4d5562;
  --nav-icon: #4d5562;
  --nav-icon-highlight: #4d5562;
  --nav-highlight: #f3f4f6;
  --nav-border: #e6e7eb;
  --nav-logo: #000001;

  --nav-dark-background: #212936;
  --nav-dark-text: #9ca3ad;
  --nav-dark-icon: #9ca3ad;
  --nav-dark-icon-highlight: #9ca3ad;
  --nav-dark-highlight: #394151;
  --nav-dark-border: #394150;
  --nav-dark-logo: #ffffff;

  --sidebar-background: #ffffff;
  --sidebar-text: #111827;
  --sidebar-text-highlight: #111827;
  --sidebar-icon: #6c727f;
  --sidebar-icon-highlight: #111827;
  --sidebar-highlight: #f3f4f6;
  --sidebar-border: #e6e7eb;

  --sidebar-dark-background: #212936;
  --sidebar-dark-text: #ffffff;
  --sidebar-dark-text-highlight: #ffffff;
  --sidebar-dark-icon: #9da3ae;
  --sidebar-dark-icon-highlight: #ffffff;
  --sidebar-dark-highlight: #394151;
  --sidebar-dark-border: #394150;

  --sumrise-dark-blue: #133249;
}

.blurry-bottom::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; /* Adjust the height of the blurry area as needed */
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* Adjust the gradient as needed */
  pointer-events: none;
}

div[data-testid="flowbite-tooltip"] {
  z-index: 999;
}

div[data-testid="table-element"] {
  overflow: visible;
}

.card-details div[data-testid="table-element"],
.feature-list div[data-testid="table-element"] {
  box-shadow: none;
}

.stripe-wrapper div:nth-child(1) {
  width: 412px;
  height: 90vh;
  overflow: scroll;
  margin: 3rem;
  border-radius: 13px;
}

#portal-root {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999999999;
}
