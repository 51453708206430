.m-bar-chart {
  .recharts-tooltip-cursor {
    fill: rgba(#f3f4f6, 0.65);
  }
}

.dark {
  .m-bar-chart {
    .recharts-tooltip-cursor {
      fill: rgba(255, 255, 255, 0.05);
    }
  }
}
