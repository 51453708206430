.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
  stroke: #e5e7eb;
}

.recharts-cartesian-axis-tick text {
  text-transform: capitalize;
}

.recharts-cartesian-axis-tick-value {
  fill: #6b7280;
}

.dark {
  .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid-vertical line {
    stroke: #51556b;
  }

  .recharts-cartesian-axis-tick-value {
    fill: #8b8e9c;
  }
}
